table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 12px;
}

table td,
table th {
  padding: 15px;
  font-size: 0.8rem;
  text-align: left;
}
table td {
  font-size: 0.75rem !important;
}
table tr {
  border-radius: 8px;
  transition: all ease-in-out 0.3s;
}

table th,
tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: #00000061;
  font-weight: 400;
}

.heavy {
  font-weight: 600;
}
table input {
  border: 0.5px solid #00000025;
  border-radius: 3px;
}

.delete-btn {
  font-size: 1rem;
  color: red;
  cursor: pointer;
}
table thead {
  background-color: rgb(245, 245, 245);
}

thead th {
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 500;
}

tbody tr {
  border-bottom: 0.3px solid #e6e6e661;
}

tbody tr td {
  padding-top: 15px;
  padding-bottom: 15px;
}

.inactive,
.activated,
.declined {
  display: flex;
  align-items: flex-start;
}

.inactive p {
  color: #ff8f00 !important;
  background-color: rgba(255, 145, 0, 0.06) !important;
  padding: 6px 8px;
  border-radius: 3px;
  min-width: 85px;
}
.activated p {
  color: #33d69f !important;
  background-color: rgba(51, 214, 160, 0.06) !important;
  padding: 6px 8px;
  border-radius: 3px;
  min-width: 85px;
}
.declined p {
  color: #d63333 !important;
  background-color: rgba(214, 51, 51, 0.06) !important;
  padding: 6px 8px;
  border-radius: 3px;
  min-width: 85px;
}

.phone-td {
  padding: 20px 8px;
}

.dots {
  font-weight: 700;
  font-size: 1rem !important;
  position: relative !important;
}

.dots:hover {
  cursor: pointer;
}

.vehicle-options {
  position: absolute;
  right: 0;
  top: 40px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 1.6px 2px 2.2px rgba(0, 0, 0, 0.02),
    3.8px 4.9px 5.3px rgba(0, 0, 0, 0.028),
    7.1px 9.3px 10px rgba(0, 0, 0, 0.035),
    12.7px 16.5px 17.9px rgba(0, 0, 0, 0.042),
    23.8px 30.9px 33.4px rgba(0, 0, 0, 0.05), 57px 74px 80px rgba(0, 0, 0, 0.07);
  display: none !important;
}

.vehicle-options ul {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.vehicle-options ul li {
  font-size: 0.8rem;
  font-weight: 400;
  transition: all ease-in-out 0.2s;
}
.vehicle-options ul li:hover {
  color: #0075c9;
}

.show-vehicle-options {
  display: flex !important;
  z-index: 30;
}

.vehicle-options ul li {
  margin-bottom: 5px;
}

tbody tr {
  position: relative;
}
