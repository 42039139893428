* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
html {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.pay-btn-container {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.pay-btn-container button {
  width: 45%;
  font-size: 0.7rem;
  font-weight: 600;
}
.app {
  display: grid;
  width: 100vw !important;
  min-height: 100vh;
  grid-template-columns: 220px calc(100% - 220px);
  background-color: #f8f8fb;
  min-height: 100vh;
  position: relative;
}
ul {
  list-style: none;
}
ul li {
  margin: 0;
  cursor: pointer;
  font-weight: 500;
}

.logout {
  /* background-color: red; */
  border-radius: 3px;
  /* width: 40px; */
  /* height: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 6px;
  border: 0.5px solid rgba(128, 128, 128, 0.586);
  color: rgb(0, 0, 0);
  margin-top: 10px;
  transition: all ease-in-out 0.3s;
  text-decoration: none;
  font-size: 0.8rem;
  padding: 5px;
}
.logout:hover {
  background-color: rgba(255, 0, 0, 0.766);
  color: white;
  border: none;
}

.broker-details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  column-gap: 20px;
}

.broker-details h5,
.broker-details p {
  margin-bottom: 6px;
}

.broker-details p {
  color: gray;
  font-size: 0.8rem;
}

ul a {
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.logomark {
  position: absolute;
  left: -10px;
  bottom: 0;
}

.success-modal {
  background-color: rgba(0, 0, 0, 0.374);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  backdrop-filter: blur(3px);
  display: flex;
  opacity: 0;
  z-index: -1;
  transition: all ease-in-out 0.5s;
}

.show-success-modal {
  opacity: 1;
  z-index: 201;
}

.modal {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  border-radius: 7px;
  width: 400px;
  text-align: center;
  z-index: 191 !important;
}

.checkmark {
  width: 28px;
  margin: 20px 0;
}

.modal p {
  margin-bottom: 20px;
  font-size: 1rem !important;
  color: gray;
}
.modal span {
  font-size: 0.6rem;
  border: 1px solid black;
  border-radius: 4px;
  padding: 4px;
}
.duplicate-container {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
}
.alert-cancel {
  font-size: 2rem;
  margin-bottom: 10px;
}
.modal h4 {
  margin-bottom: 20px;
}

.upload-container {
  position: relative;
  border: 0.5px solid gray;
  width: 80%;
  height: 250px;
  margin-bottom: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.upload-container img {
  width: 50px;
  margin-bottom: 30px;
}
.upload-container h2 {
  color: rgb(123, 123, 123);
  font-size: 1.2rem;
}
.upload-file {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100% !important;
  z-index: 30;
  opacity: 0;
  cursor: pointer;
}

.credit-note-input {
  width: 80% !important;
}
