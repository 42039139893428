.login-page-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #0075c9;
  overflow: hidden;
  padding: 10px 0;
}

.admin-container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  padding: 10px;
  flex-direction: column;
}
.admin-container h1 {
  font-size: 1.7rem;
  color: #00000061;
}
.admin-wrapper {
  width: 50%;
  border: 0.5px solid rgba(0, 0, 0, 0.382);
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-data {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.no-data h1 {
  font-size: 1.5rem;
}
.no-data p {
  font-size: 1rem;
  margin-top: 10px;
}

.no-data p span {
  color: #0075c9;
  font-weight: 700;
}

.searchbox {
  width: 400px !important;
}

.all {
  color: #0075c9 !important;
  background-color: #0075c910 !important;
}
.pending {
  color: #ff8f00 !important;
  background-color: rgba(255, 145, 0, 0.06) !important;
}

.valid {
  color: #33d69f !important;
  background-color: rgba(51, 214, 160, 0.06) !important;
}
.clients-btn-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  transition: all ease-in-out 0.2s;
}

.clients-btn-container button {
  width: 200px;
  cursor: pointer;
  height: 75px;
  background-color: unset;
  border: 0.5px solid #00000061;
}

.btn-search {
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: row;
  /* border: 0.5px solid #00000029; */
  border: none;
  border-radius: 3px;
  gap: 7px;
  background-color: #034ace0a;
  padding: 10px;
  cursor: pointer;
}

.full-process {
  background-color: rgba(128, 128, 128, 0.053) !important;
  backdrop-filter: blur;
}
.upload-notes {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px;
}

.upload-notes h2,
.upload-notes p {
  margin-bottom: 15px;
}

.admin-wrapper input,
.admin-wrapper select {
  width: 100%;
  margin-bottom: 30px;
  padding: 12px;
  border-radius: 3px;
  border: 0.5px solid gray;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.08;
}

.blue-backdrop {
  width: 100%;
  height: 100%;
  opacity: 0.91;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 20px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 20px;
  gap: 10px;
}

.page-item {
  width: 32.8px;
  height: 30.59px;
  border: 0.5px solid #00000061;
  cursor: pointer;
}
.page-item a {
  width: 100%;
  height: 100%;
}

.confirmation-modal {
  background-color: #0075c9;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 40px;
  position: absolute;
  top: -25%;
  /* left: ; */
  right: 0;
  color: white !important;
  box-shadow: 1.5px 0.7px 2.2px rgba(0, 0, 0, 0.02),
    3.7px 1.6px 5.3px rgba(0, 0, 0, 0.028), 7px 3px 10px rgba(0, 0, 0, 0.035),
    12.5px 5.4px 17.9px rgba(0, 0, 0, 0.042),
    23.4px 10px 33.4px rgba(0, 0, 0, 0.05), 56px 24px 80px rgba(0, 0, 0, 0.07);
  transition: all ease-in-out 0.6s;
}

.show-confirmation-modal {
  top: 0;
}

.confirmation-modal h2 {
  margin-bottom: 20px;
}

.confirmation-modal p {
  color: white !important;
}
.login-texts {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  color: white;
  letter-spacing: 6px;
  padding-left: 100px;
}

.login-texts h2 {
  font-size: 50px;
  font-weight: 300;
  margin-bottom: 20px;
}
.login-texts h1 {
  font-size: 50px;
  font-weight: 700;
}
.login-texts p {
  margin-top: 20px;
}

.login-panel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.action-btn-container {
  /* background-color: red; */
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.input-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.export-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  background-color: #0080009d;
  border: none;
  padding: 7px 20px;
  border-radius: 5px;
  font-size: 0.8rem;
  font-family: "Roboto", sans-serif;
  /* font-weight: ; */
  text-decoration: none;
  color: white;
}

.paginationBtns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 10px;
  padding: 3px 8px;
  background-color: transparent;
  padding: 10px;
  min-width: 350px !important;
  font-size: 0.8rem;
  border-radius: 5px;
  color: gray !important;
  align-self: flex-end;
}

.paginationActive {
  background-color: #0075c991;
  border-radius: 2px;
  padding: 7px 13px;
  color: white !important;
}

.paginationActive a {
  color: white !important;
}

.paginationDisabled a {
  color: gray !important;
}

.login-panel {
  background-color: white;
  /* min-height: 24.5625rem; */
  max-width: 28.375rem !important;
  width: 90% !important;
  border-radius: 8px;
  padding: 30px 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.white-logo {
  margin-bottom: 40px;
  width: 200px;
  display: none;
}

.login-panel input,
.login-panel select {
  width: 100%;
  height: 3.1rem;
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 10px;
  border: 0.5px solid #0075c9;
  font-size: 1.2rem;
}

.login-panel h2 {
  margin-bottom: 20px;
  color: rgb(72, 72, 72);
}

.btn-login {
  width: 100%;
  height: 2.6rem;
  border-radius: 6px;
  color: white;
  background-color: #0075c9;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 1.1rem;
  font-family: "Roboto", sans-serif;
  letter-spacing: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.login-panel p {
  font-weight: 300;
  color: gray;
  letter-spacing: 2px;
  font-size: 0.8rem;
}
.login-panel p span {
  color: #0075c9;
  cursor: pointer;
  font-weight: 600;
}

.navbar {
  /* background-color: red; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  padding-left: 125px;
  z-index: 70 !important;
}

.white-logos {
  width: 150px;
}

.customer-container {
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  transition: all ease-in-out 0.5s;
  background-color: rgba(0, 0, 0, 0.435);
  z-index: 92;
  backdrop-filter: blur(2px);
  display: none;
}

.payout-top,
.payout-bottom {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 10px;
}
.payout-top span,
.payout-bottom span,
.email-div span {
  color: #00000061;
  font-size: 0.8rem;
  margin-bottom: 4px;
}

.payout-top p,
.payout-bottom p,
.email-div p {
  font-weight: 500;
  font-size: 0.9rem;
}

.payout-bottom {
  margin-top: 40px;
}

.ft-info {
  position: absolute;
  width: 450px !important;
  z-index: 200 !important;
}

.ft-bottom {
  margin: unset;
}

.nav-upload-container {
  width: 100%;
  background-color: rgba(128, 128, 128, 0.04);
}

.email-div {
  width: 80%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.hide-upload {
  opacity: 0;
}

.fleet-container {
  width: 450px !important;
  min-height: 350px !important;
  text-align: center;
  z-index: 190 !important;
}

.fleet-container h2 {
  font-size: 1rem;
}

.nav-uploads {
  /* background-color: red; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  width: 100%;
  align-content: center;
  align-items: center;
  border-bottom: 0.5px solid rgba(128, 128, 128, 0.331);
  margin-bottom: 10px;
  padding-bottom: 20px;
}

.premium-info {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-size: 0.8rem;
}

.nav-uploads .nav-link {
  /* background-color: blue; */
  width: 100%;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 4px;
}

.amount-due {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 80%;
  color: white;
  background-color: #1e2139;
  padding: 20px 5px;
  border-radius: 5px;
  margin: 10px 0;
}
.hide-btn {
  display: none !important;
}
.pmt-information {
  width: 500px !important;
}
.pmt-container {
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  transition: all ease-in-out 0.5s;
  background-color: rgba(0, 0, 0, 0.435);
  z-index: 190;
  backdrop-filter: blur(2px);
  display: none;
}

.show-customer-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}
.show-pmt-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.log-out {
  width: 300px !important;
  text-align: center;
}
.log-out p {
  margin: 30px 0;
}
.log-out button,
.log-out a {
  width: 70px;
  height: 30px;
  margin-left: 3px;
  margin-right: 3px;
}

.log-out button {
  border: none;
  border: 0.5px solid gray;
  border-radius: 3px;
  background-color: white;
  cursor: pointer;
  transition: all ease-in-out 0.2s !important;
}

.log-out a {
  color: black;
  text-decoration: none;
  padding: 5px 6px;
  border-radius: 4px;
  transition: all ease-in-out 0.2s !important;
}
.log-out a:hover {
  background-color: rgb(247, 48, 48);
  color: white;
}

.log-out button:hover {
  background-color: #0075c9;
  color: white;
}

.log-out-container {
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* .users-form {
  width: 600px !important;
}
.users-form input,
.users-form select {
  height: 2.3rem !important;
  margin-bottom: 8px !important;
} */

.history-container {
  width: unset !important;
}
.fleet-frequency {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: flex-start;
}
.customer-information {
  /* background-color: red; */
  padding: 20px;
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
  background-color: white;
  border-radius: 4px;
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
}
.customer-information h1 {
  font-size: 1.4rem;
  color: #8ba5b7;
}
.car-details {
  width: 100% !important;
  box-shadow: none;
}

.car-details input {
  font-size: 1.1rem;
  border: 0.5px solid #aecade !important;
}

.customer-information input,
.customer-information select {
  margin-bottom: 12px !important;
  width: 100%;
  height: 2.4rem;
  border-radius: 2px !important;
  /* margin-bottom: 15px; */
  padding: 10px;
  border: 0.5px solid #0075c97f;
}
.error {
  border: 1.5px solid red !important;
}
.customer-information input::placeholder {
  font-size: 0.7rem;
}

.customer-information-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 20px;
}
.btn-close {
  font-size: 1.3rem;
  cursor: pointer;
  color: red;
}
.third-party-head {
  margin: 8px 0;
  align-self: flex-start;
  font-size: 0.8rem;
  font-weight: 600;
}
.reg {
  width: 100% !important;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
label {
  color: gray;
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.login-panel label {
  font-size: 1rem !important;
}

.double-input {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 100%;
}
.triple-input {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  width: 100%;
}

.account-type {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 10px;
}

.account-type button {
  width: 150px;
  padding: 12px 0;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
}

.account-type button:hover {
  background-color: #0075c95f;
  color: white;
}

.btn-individual {
  background-color: white;
  color: black;
  border: 0.5px solid rgb(187, 187, 187);
}
.btn-corporate {
  background-color: white;
  color: black;
  border: 0.5px solid rgb(187, 187, 187);
}

.btn-next {
  width: 100%;
  height: 2.6rem;
  border-radius: 6px;
  color: white;
  background-color: #0075c9;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 1.1rem;
  font-family: "Roboto", sans-serif;
  letter-spacing: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all ease-in-out 0.2s;
}
.btn-next:hover {
  background-color: #0075c9af;
}
.header {
  color: rgb(56, 56, 56);
}

.upload-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.download-template {
  /* background-color: red !important; */
  /* color: black !important; */
  text-decoration: none !important;
}

.pay-div {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.third-party-header {
  /* background-color: red; */
  width: 80%;
  margin-top: 20px;
  margin-bottom: 5px;
}

.search-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 10px;
}

.search-container input {
  height: 100%;
  width: 250px;
  border: 0.5px solid rgba(128, 128, 128, 0.191);
  border-radius: 3px;
  padding: 4px;
}

.third-party-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.upload-type div {
  border: 0.5px solid gray;
  width: 280px;
  height: 250px;
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  letter-spacing: 4px;
  transition: all ease-in-out 0.3s;
}

.upload-type div:hover {
  background-color: #0075c948;
  color: white;
}

.filter {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-direction: column;
  width: 100%;
  max-width: 260px !important;
  border: 1px solid rgba(128, 128, 128, 0.372);
  padding: 9px;
  font-weight: 200;
  font-size: 1.1rem;
  cursor: pointer;
  position: relative;
  align-self: flex-start;
}
.filter p {
  margin-left: 5px;
  color: rgb(0, 0, 0) !important;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 1rem;
}

.filter-svg {
  color: #0075c9 !important;
  margin-right: 15px;
}

.filter-list {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  border-radius: 4px;
  z-index: 3;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(128, 128, 128, 0.393);
  height: 0;
  overflow: hidden;
  transition: all ease-in-out 0.17s;
}
.show-filter-list {
  height: unset;
  padding-bottom: 5px;
  border: 1px solid black;
}

.filter-list button {
  background-color: transparent;
  border: none;
  margin-top: 7px;
  cursor: pointer;
  font-family: var(--kumbh);
  font-size: 0.9rem;
  color: var(--gray);
  text-align: left;
  width: 100%;
  padding: 0 20px;
  transition: all ease-in-out 0.17s;
  align-items: flex-start;
}

@media (max-width: 1024px) {
  .login-texts {
    padding-left: 20px;
  }

  .login-texts h2 {
    font-size: 30px;
  }
  .login-texts h1 {
    font-size: 50px;
  }
  .login-texts p {
    margin-top: 15px;
  }
  .login-panel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .login-panel {
    width: 23.375rem;
  }

  .navbar {
    padding-left: 70px;
  }

  .login-panel input,
  .login-panel select {
    height: 2.7rem;
  }

  .btn-login {
    font-size: 0.9rem;
  }
}

@media (max-width: 870px) {
  .login-texts {
    padding-left: 20px;
  }

  .login-texts h2 {
    font-size: 20px;
  }
  .login-texts h1 {
    font-size: 40px;
  }
  .login-texts p {
    font-size: 10px;
  }
}
@media (max-width: 800px) {
  .blue-backdrop {
    width: 100%;
    height: 100%;
    opacity: 0.91;
    display: flex;
    padding: 0px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: unset;
    margin: 15px 0;
  }
  .login-texts h2 {
    display: none;
  }
  .white-logos {
    width: 100px;
  }
  .login-texts {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: unset;
    margin-bottom: 20px;
    text-align: center;
  }
  .login-texts p {
    font-size: 10px;
  }
  .login-texts h1 {
    font-size: 40px;
  }
  .logomark {
    position: absolute;
    left: -10px;
    bottom: 0;
    display: none;
  }
}

@media (max-width: 400px) {
  .login-texts h1 {
    font-size: 25px;
  }
  .login-texts p {
    font-size: 9px;
  }
  .login-panel h2 {
    margin-bottom: 20px;
    color: rgb(72, 72, 72);
    font-size: 1.2rem;
  }
}
