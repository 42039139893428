.dashboard-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;
  /* background-color: blue; */
}

.modal-background {
  background-color: rgba(0, 0, 0, 0.168);
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  transition: all ease-in-out 0.5s;
  backdrop-filter: blur(2px);
  z-index: 91;
}

.show-modal-background {
  display: unset;
}

.navigation {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 40px;
  border-right: 0.5px solid rgba(128, 128, 128, 0.278);
  /* padding: 20px 0; */
  z-index: 90;
  padding-top: 40px;
}
.navigation ul {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  margin-top: 60px;
  row-gap: 20px;
}

.nav-link {
  margin: 0 !important;
  width: 80%;
  padding: 10px 5px;
  border-radius: 7px;
  font-size: 0.8rem !important;
  transition: all ease-in-out 0.2s;
  color: #00000061;
}
.nav-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 10px;
}

.icon {
  margin-right: 8px;
}

.active {
  background-color: #0075c9 !important;
  color: #0075c9 !important;
  border-radius: 2px;
  font-weight: 500;
}

.link {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.active li {
  color: white;
}

.navigation-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dashboard {
  padding: 0 30px;
  width: 100%;
  height: calc(100vh - 78.4px);
  /* background-color: red; */
  overflow-y: scroll;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}

.generate-btn {
  background-color: #0075c9;
  border: 0.5px solid rgba(128, 128, 128, 0.308);
  padding: 8px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.dashboard p {
  color: rgba(0, 0, 0, 0.382);
  letter-spacing: 2px;
}
.dashboard h1 {
  letter-spacing: 2px;
}

.logo-container {
  /* background-color: blue; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding: 40px 10px; */
}

.logo-container img {
  width: 120px;
}

.dashboard-header {
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 40px;
  width: 100%;
}

.dashboard-header h1 {
  color: #8ba5b7;
  font-size: 1.7rem;
}
.dashboard-button-container {
  gap: 10px;
  /* background-color: blue; */
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.dashboard-header button {
  width: 135px;
  height: 35px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  background-color: #0075c9;
  border: none;
  color: white;
  border-radius: 5px;
  transition: all ease-in-out 0.5s;
}
.dashboard-header button:hover {
  background-color: #0075c9ce;
}

.individual-details {
  width: 100%;
  padding: 17px 7px;
  background-color: #f1f6fd8b;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border-radius: 8px;
  margin-bottom: 10px;
  transition: all ease-in-out 0.3s;
}

.individual-details p {
  color: black;
  font-size: 0.9rem;
}
.individual-header {
  width: 100%;
  padding: 23px 7px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  color: #97a1ab;
}

.individual-header p {
  font-size: 0.8rem;
}

.individual-details:hover {
  background-color: #ddf1ff;
  cursor: pointer;
}
.vehicle-container {
  /* background-color: red */
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.triple-div {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 40px 0;
  align-items: center;
  justify-items: center;
}

.double-div {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 40px 0;
  justify-items: center;
}

.dashboard-wrapper {
  width: 100%;
  height: calc(100% - 115px);
  display: grid;
  background-color: pink;
  grid-template-columns: 1.1fr 2fr;
  grid-gap: 10px;
}
.dash-left {
  width: 100%;
  /* height: calc(100% - 115px); */
  height: 100%;
  background-color: green;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: repeat(2, 1fr);
}
.dash-left-top {
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: repeat(2, 1fr);
}
.dash-left-bottom {
  background-color: rgba(0, 255, 85, 0.107);
  border: 0.5px solid black;
  padding: 3px;
}

.dash-right {
  width: 100%;
  height: calc(100% - 115px);
  display: grid;
  grid-gap: 10px;
  grid-template-rows: repeat(2, 1fr);
}

.dash-right-bottom {
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
}

.dash-right-top {
  background-color: rgba(255, 0, 0, 0.107);
  border: 0.5px solid black;
}

.dash-right-bottom-left {
  background-color: rgba(204, 255, 0, 0.107);
  border: 0.5px solid black;
}
.dash-right-bottom-right {
  background-color: rgba(255, 0, 0, 0.107);
  border: 0.5px solid black;
}

.dash-left-top-top {
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
}
.dash-left-top-bottom {
  background-color: rgba(98, 0, 255, 0.107);
  border: 0.5px solid black;
}

.search-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 10px;
}
.search-wrapper input {
  width: 60%;
}

.search-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-size: 0.7rem !important;
  width: 30%;
}
